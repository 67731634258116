/**
 * 功能说明：路由：异常页面
 */

import { RouteRecordRaw } from "vue-router";
const NotFound = () => import("@/views/anomaly-page/not-found/index.vue"); // 404页
const NoAccess = () => import("@/views/anomaly-page/no-access/index.vue"); // 无权限页

const AnomalyPageRouter: Array<RouteRecordRaw> = [
  {
    path: "/no-access",
    name: "noAccess",
    component: NoAccess,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "notFound",
    component: NotFound,
    meta: {
      requiresAuth: false,
    },
  },
];

export default AnomalyPageRouter;

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  id: "app"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(), _createBlock(_KeepAlive, null, [_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_component_router_view, {
    key: _ctx.key
  })) : _createCommentVNode("", true)], 1024)), !_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_component_router_view, {
    key: _ctx.key
  })) : _createCommentVNode("", true)]);
}
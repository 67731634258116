/**
 * 功能说明：路由：首页
 */

const Home = () => import("@/views/home/pc/download/index.vue"); // 首页

const homeRouter = [
  {
    path: "/",
    component: Home,
    name: "home",
    meta: {
      requiresAuth: false,
    },
    children: [
      {
        path: "",
        component: () => import("@/views/home/pc/download/download.vue"),
        name: "pchome",
        meta: {
          requiresAuth: false,
        },
      },
    ],
  },
  {
    path: "/h5home",
    component: () => import("@/views/home/h5/index.vue"),
    meta: {
      requiresAuth: false,
    },
    children: [
      {
        path: "",
        component: () => import("@/views/home/h5/download.vue"),
        name: "h5home",
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: "h5downloaddetail",
        component: () => import("@/views/home/h5/downloaddetail.vue"),
        name: "h5downloaddetail",
        meta: {
          requiresAuth: false,
        },
      },
    ],
  },
];

export default homeRouter;

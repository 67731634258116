import { createRouter, createWebHistory } from "vue-router";
import homeRouter from "./home/index"; // 用户中心模块
import anomalyPageRouter from "./anomaly-page/index"; // 异常页面

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [...homeRouter, ...anomalyPageRouter],
});

export default router;
